<template>
  <v-card max-height="100%">
    <v-card-text>
      <v-row no-gutters ref="linhaColunas">
        <v-col cols="3" class="mr-1">
          <!-- @click.ctrl="dialogAll" -->
          <!-- @click.stop="$store.dispatch('loadEventosByClient')" -->
          <Colunas
            :title="`Não Atendidos (${getEventos.length})`"
            :events="getEventos"
            :btn="true"
            @eventSelected="selEvt"
            @refresh="$store.dispatch('loadEventosByClient')"
            @multi="dialogAll"
          />
        </v-col>
        <v-col>
          <Atendimento
            v-model="event"
            @next="nextEvent($event)"
            @previous="previousEvent($event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOG TRATAMENTO -->
    <v-dialog v-model="getTratamento" width="500">
      <v-card>
        <v-card-title> Fechar Evento </v-card-title>

        <v-card-text>
          <v-row v-show="!evtToClose.real">
            <v-col>
              <v-btn color="error" block @click="evtToClose.real = 2">
                <v-icon class="mr-2">mdi-thumb-down</v-icon> Disparo Falso
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="success" block @click="evtToClose.real = 1">
                <v-icon class="mr-2">mdi-thumb-up</v-icon> Disparo Real
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="evtToClose.real">
            <v-col>
              <v-label>
                Disparo considerado :
                {{ evtToClose.real == 1 ? " Real" : " Falso" }}
              </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="evtToClose.tratamento"
                counter="1000"
                rows="2"
                label="Tratamento"
                hint="Tratamento se necessário"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" text @click="closeDialog"> Cancelar </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeEvent">
            Fechar Evento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- FIM Dialog Evento Unico -->
    <!-- Dialog Evento Multiplo -->
    <v-dialog v-model="disparoMultiplos" width="500">
      <v-card>
        <v-card-title>
          Fechar {{ evtsToClose.ids.length }} Eventos ?</v-card-title
        >

        <v-card-text>
          <v-row v-show="!evtsToClose.real">
            <v-col>
              <v-btn color="error" block @click="evtsToClose.real = 2">
                <v-icon class="mr-2">mdi-thumb-down</v-icon> Disparos Falsos
              </v-btn>
            </v-col>
            <v-col>
              <v-btn color="success" block @click="evtsToClose.real = 1">
                <v-icon class="mr-2">mdi-thumb-up</v-icon> Disparos Reais
              </v-btn>
            </v-col>
          </v-row>
          <v-row v-show="evtsToClose.real">
            <v-col>
              <v-label>
                Disparos considerado :
                {{ evtsToClose.real == 1 ? " Real" : " Falso" }}
              </v-label>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="evtsToClose.tratamento"
                counter="1000"
                rows="2"
                label="Tratamento"
                hint="Tratamento se necessário"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="warning" text @click="disparoMultiplos = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="closeEvents">
            Fechar Evento
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DIALOG LOADING -->
    <v-dialog v-model="dialogLoading" persistent max-width="290">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-row>
            <v-spacer> </v-spacer>
            <v-progress-circular indeterminate color="primary">
            </v-progress-circular>
            <v-spacer> </v-spacer>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            {{ mensagem }}
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import Colunas from "../components/Eventos/EventoColuna.vue";
import Atendimento from "../components/Eventos/Atendimento.vue";
import { mapGetters } from "vuex";
export default {
  name: "EventosCliente",
  data: () => {
    return {
      dialogLoading: true,
      event: {},
      evtToClose: { real: 0 },
      mensagem: "Logando no Sistema",
      evtsToClose: { real: 0, ids: [] },
      disparoMultiplos: false,
      windowSize: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    };
  },
  components: {
    Colunas,
    Atendimento,
  },
  computed: {
    ...mapGetters(["getEventos"]),
    getTratamento: {
      get() {
        return this.$store.getters.getTratamento;
      },
      set() {},
    },
  },
  methods: {
    selEvt(event) {
      this.event = event;
    },
    closeDialog() {
      this.$store.dispatch("setTratamento", null);
      this.evtToClose = { real: 0 };
      this.$store.dispatch("setEvento", {});
      this.$store.dispatch("loadEventosByClient");
    },
    dialogAll() {
      this.disparoMultiplos = true;
      this.evtsToClose.ids = this.getEventos.map((evt) => evt.id);
    },
    closeEvent() {
      let payload = {
        ...this.getTratamento,
        ...this.evtToClose,
        cb: this.closeDialog,
      };
      this.$store.dispatch("tratamento", payload);
    },
    closeEvents() {
      // console.log("closeEvents", this.evtsToClose);
      this.$store.dispatch("tratamentoMultiplo", {
        ...this.evtsToClose,
        cb: () => {
          this.disparoMultiplos = false;
          this.evtsToClose = { real: 0, ids: [] };
          this.$store.dispatch("loadEventosByClient");
        },
      });
    },
    login() {
      this.$http
        .post(`login/hash`, { hash: this.$route.query.hash })
        .then((resp) => {
          if (resp.status == 200) {
            let token = resp.data.token;
            this.$store.commit("setToken2", token);
            this.$store.dispatch("setImgToken", resp.data.imgToken);
            this.mensagem = "Buscando Eventos";
            this.$store.dispatch("loadEventosByClient", {
              cb: () => {
                this.mensagem = "Logando no Sistema";
                this.dialogLoading = false;
              },
            });
          }
        })
        .catch(() => {
          this.mensagem = "Não Autorizado";
          setTimeout(() => {
            this.dialogLoading = false;
          }, 3000);
        });
    },
    onResize() {
      this.windowSize = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      this.$store.dispatch("setWindowSize", this.windowSize);
    },
    nextEvent(actual) {
      let idx = this.getEventos.findIndex((evt) => evt.id == actual);
      if (idx + 1 < this.getEventos.length) {
        this.$store.dispatch("setEvento", this.getEventos[idx + 1]);
      }
    },
    previousEvent(actual) {
      let idx = this.getEventos.findIndex((evt) => evt.id == actual);
      if (idx - 1 >= 0) {
        this.$store.dispatch("setEvento", this.getEventos[idx - 1]);
      }
    },
  },
  mounted() {
    this.login();
    this.$store.dispatch("setEvento", {});
    this.$vuetify.goTo(this.$refs.linhaColunas, {
      duration: 300,
      offset: 10,
      easing: "easeInOutCubic",
    });
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style scoped>
</style>